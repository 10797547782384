import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/auth/service';
import { APP_ROUTES } from 'src/app/shared/helpers/routes.enum';
import { CookieService } from 'ngx-cookie-service';
import { HttpsService } from 'src/app/shared/services/base.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { PaymentAccountsStoreService } from '@stores/payment-accounts-store.service';
import { PAYMENT_POP_STATE } from '@parameters/payments/payment-account.parameter';
import { environment } from '@environments/environment';
import { ApmUserService } from '@stores/apm-user.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  subscription!: Subscription;
  isBrowserRefresh: any;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private http: HttpsService,
    private cookieService: CookieService,
    private _toastService: ToasterService,
    private readonly _paymentAccountService: PaymentAccountsStoreService,
    private apmStoreService: ApmUserService,
    private readonly _route: ActivatedRoute
  ) {
    this.subscription = _router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isBrowserRefresh = !_router.navigated;
      }
    });
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = request;
    const token = this.cookieService.get('refreshToken');
    if (token != null) {
      authReq = this.addTokenHeader(request, token);
    }
    return next.handle(request).pipe(
      catchError((err) => {
        if (
          /**
           * Condition to check whether the PaymentAccountAuthentication endpoint and payment endpoints return 401/403 errors
           * then open confirm password modal to authenticate paymentAccessToken
           */
          (authReq.url?.includes('v1/auth/gateway-accounts') ||
            authReq.url?.includes(environment.paymentApiBaseUrl)) &&
          [401, 403].indexOf(err.status) !== -1
        ) {
          this._paymentAccountService.paymentModalState$.next(
            PAYMENT_POP_STATE.OPEN
          );
          return throwError(() => err.error);
        }

        // Add Conditions for the MFA Flow
        if (
          (authReq.url?.includes(EndpointsParameter.VerifyMfaForLogin) ||
            authReq.url?.includes(
              EndpointsParameter.VerifyMfaForEntityLogin
            )) &&
          [401]?.indexOf(err.status) !== -1
        ) {
          this._toastService.error(err?.error?.message);
          const returnUrl = this._route?.snapshot?.queryParams?.['return-url'];
          if (this._router.url?.startsWith('/mfa')) {
            this._router.navigate(['/login'], { queryParams: { ['return-url']: returnUrl } });
          } else {
            this._router.navigate(['/entity/login'], {
              queryParams: { ['return-url']: returnUrl },
            });
          }
          return throwError(() => err.error);
        }

        if ([401].indexOf(err.status) !== -1) {
          if (!this.isBrowserRefresh) {
          }
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api

          if (!err?.url?.includes('refresh')) {
            return this.handle401Error(authReq, next);
          }

          // ? Can also logout and reload if needed;
        }

        if (
          [400, 402, 403, 404, 500, 501, 502, 412].indexOf(err.status) !== -1
        ) {
          if (
            this._router.url.includes(APP_ROUTES.merchant_boarding) &&
            authReq.method === 'POST' &&
            err?.error?.errors.length > 0
          ) {
            return throwError(() => err.error);
          }
          if (
            err?.error?.message &&
            (authReq.method === 'GET'
              ? this._router.url.includes(APP_ROUTES.business_detail)
                ? false
                : true
              : true)
          ) {
            this._toastService.error(err.error.message);
          }
        }

        // throwError
        return throwError(() => err.error);
      })
    );
  }

  private unAuthorized() {
    this._authenticationService.token = '';
    const errMsg = 'Not Authenticated';
    this._toastService.error(errMsg);
    this._authenticationService.logout();
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      let refreshToken = this.cookieService.get('refreshToken');
      let httpParams = new HttpParams().set('refreshToken', refreshToken);

      if (!refreshToken) {
        this.unAuthorized();
      }

      if (refreshToken)
        return this.http.httpGetWithQuery('refreshWeb', httpParams).pipe(
          switchMap((res: any) => {
            this.isRefreshing = false;
            this.cookieService.set('isoAccessToken', res.token, { path: '/' });
            this.refreshTokenSubject.next(res.token);

            this.apmStoreService.setUserContext();
            return next.handle(this.addTokenHeader(request, res.token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.unAuthorized();
            return throwError(() => new Error(err));
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    this.cookieService.delete('accessToken');
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }
}
